// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer-section">
        <div className="container">
          <div className="footer-content pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="#" className="logo d-flex align-items-center">
                      <img src="assets/images/logo.png" alt="logo" />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      At Light of Web we are passionate about providing businesses
                      with the IT solutions they need to succeed in today's
                      competitive marketplace.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="#" className="twitter">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#" className="facebook">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#" className="instagram">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                <div className="service-widget footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Services</h3>
                  </div>
                  <ul className="list">
                    <li>
                      <a href="single-service.html">Web Design</a>
                    </li>
                    <li>
                      <a href="single-service.html">App Developemnt</a>
                    </li>
                    <li>
                      <a href="single-service.html">Cloud Services</a>
                    </li>
                    <li>
                      <a href="single-service.html">Domain And Hosting</a>
                    </li>
                    <li>
                      <a href="single-service.html">Seo Optimization</a>
                    </li>
                    <li>
                      <a href="single-service.html">Social Media</a>
                    </li>
                    <li>
                      <a href="single-service.html">Data Secure</a>
                    </li>
                    <li>
                      <a href="single-service.html">Web Hosting</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                <div className="service-widget footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Information</h3>
                  </div>
                  <ul className="list">
                    <li><Link to="/about-us">About Us</Link></li>
                    <li>
                      <a href="pricing.html">Pricing</a>
                    </li>
                    <li>
                      <a href="faqs.html">FAQs</a>
                    </li>
                    <li>
                      <a href="team.html">Team</a>
                    </li>
                    <li>
                      <a href="blogs.html">Blogs</a>
                    </li>
                    <li>
                      <a href="single-blog.html">Single Blog</a>
                    </li>
                    <li>
                      <a href="page.html">Terms &amp; Conditions</a>
                    </li>
                    <li>
                      <a href="page.html">Sample Page</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="contact-widget footer-widget mb-25">
                  <div className="footer-widget-heading">
                    <h3>Contacts</h3>
                  </div>
                  <div className="footer-text">
                    <p>
                      <i className="bi bi-geo-alt-fill mr-15"></i> C-149 Subhan Tower Khajarana Indore MP India 452016
                    </p>
                    <p>
                      <i className="bi bi-telephone-inbound-fill mr-15"></i> +91 8120 0673 73
                    </p>
                    <p>
                      <i className="bi bi-envelope-fill mr-15"></i>{" "}
                      info@lightofweb.in
                    </p>
                  </div>
                </div>
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Newsletter</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don't miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        Subscribe<span></span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 text-center">
                <div className="copyright-text">
                  <p>
                    lightofweb<span>.</span> © 2025 - Designed by www.lightofweb.in
                    <a href="#">Light of Web</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
