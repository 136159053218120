// const Card = ({url, icon, title, description}) => {
//   return (
//     <>
//       <div className="col-xl-4 col-md-6 aos-init aos-animate" data-aos="fade-right">
//         <a href={url}>
//           <div className="icon-box">
//             <div className="icon">
//               <i className={icon}></i>
//             </div>
//             <h4 className="title">{title}</h4>
//             <p>{description}
//             </p>
//             <span></span>
//           </div>
//         </a>
//       </div>
//     </>
//   );
// };

// export default Card;

const Card = ({ data, cardOnClick }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <div

            className="col-xl-4 col-md-6 aos-init aos-animate"
            data-aos="fade-right"
            key={index}
          >
            <a href={item?.url}>
              <div className="icon-box">
                <div className="icon">
                  <i className={item?.icon}></i>
                </div>
                <h4 className="title" >{item?.title}</h4>
                <p>{item?.description}</p>
                <span></span>
              </div>
            </a>
          </div>
        );
      })}
    </>
  );
};

export default Card;
