import React from "react";

const Projects = () => {
  return (
    <div>
       <div class="breadcrumbs">
    <div class="page-header d-flex align-items-center">
      <div class="container position-relative">
        <div class="row d-flex justify-content-start">
          <div class="col-lg-8 text-left">
            <h2>Our Portfolio</h2>
            <p>Showcases a variety of projects that we have completed for our clients</p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div class="container">
        <ol>
          <li><a href="index.html">Home</a></li>
          <li>Our Portfolio</li>
        </ol>
      </div>
    </nav>
  </div>

  <div class="single-page">

    <section id="portfolio" class="portfolio">
      <div class="container" data-aos="fade-up">
        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry"
          data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">
          <div>
            <ul class="portfolio-flters">
              <li data-filter="*" class="filter-active">All <span></span></li>
              <li data-filter=".filter-app">App Design <span></span></li>
              <li data-filter=".filter-product">App Development <span></span></li>
              <li data-filter=".filter-branding">Branding <span></span></li>
              <li data-filter=".filter-books">IT Solutions <span></span></li>
            </ul>
          </div>

          <div class="row gy-4 portfolio-container">

            <div class="col-xl-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <a href="single-project.html" data-gallery="portfolio-gallery-app">
                  <img src="assets/images/portfolio/project-1.jpg" class="img-fluid" alt="" /></a>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 portfolio-item filter-product">
              <div class="portfolio-wrap">
                <a href="single-project.html" data-gallery="portfolio-gallery-app">
                  <img src="assets/images/portfolio/project-2.jpg" class="img-fluid" alt="" /></a>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
              <div class="portfolio-wrap">
                <a href="single-project.html" data-gallery="portfolio-gallery-app">
                  <img src="assets/images/portfolio/project-3.jpg" class="img-fluid" alt="" /></a>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 portfolio-item filter-books">
              <div class="portfolio-wrap">
                <a href="single-project.html" data-gallery="portfolio-gallery-app">
                  <img src="assets/images/portfolio/project-4.jpg" class="img-fluid" alt="" /></a>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <a href="single-project.html" data-gallery="portfolio-gallery-app"><img src="assets/images/portfolio/project-5.jpg" class="img-fluid" alt="" /></a>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
              <div class="portfolio-wrap">
                <a href="single-project.html" data-gallery="portfolio-gallery-app"><img src="assets/images/portfolio/project-6.jpg" class="img-fluid" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
    </div>
  );
};

export default Projects;
