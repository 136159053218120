import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
     {/* <div id="preloader"></div> */}

  <header id="header" className="header d-flex align-items-center sticked stikcy-menu">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
      <a href="index.html" className="logo d-flex align-items-center">
        <img src="assets/images/logo.png" alt="logo" />
      </a>
      <nav id="navbar" className="navbar">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/teams">Teams</Link></li>
          <li><Link to="/blogs">Blogs</Link></li>

        </ul>
      </nav>
      <a href="contact.html" className="d-none d-md-flex default-theme-btn-one">Contact Us <span></span></a>
      <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
      <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
    </div>
  </header>
    </>
  );
};

export default Navbar;
