import React from "react";

const Teams = () => {
  return (
    <div>
       <div className="breadcrumbs">
    <div className="page-header d-flex align-items-center">
      <div className="container position-relative">
        <div className="row d-flex justify-content-start">
          <div className="col-lg-8 text-left">
            <h2>Expert Team</h2>
            <p>Our team of experienced professionals is committed to understanding your unique business needs</p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div className="container">
        <ol>
          <li><a href="index.html">Home</a></li>
          <li>Expert Team</li>
        </ol>
      </div>
    </nav>
  </div>

  <div className="single-page">

    <section id="team" className="team sections-bg">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="row gy-4">
          <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
              <img src="assets/images/team/team-1.jpg" className="img-fluid" alt="" />
              <h4>Jhone Bi</h4>
              <span>Application Manager</span>
              <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <div className="member">
              <img src="assets/images/team/team-2.jpg" className="img-fluid" alt="" />
              <h4>Sani Awesome</h4>
              <span>Social Media</span>
              <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div className="member">
              <img src="assets/images/team/team-3.jpg" className="img-fluid" alt="" />
              <h4>Andrio Willi</h4>
              <span>Content Writer</span>
              <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
            <div className="member">
              <img src="assets/images/team/team-4.jpg" className="img-fluid" alt="" />
              <h4>Afa Jonson</h4>
              <span>Business Manager</span>
              <div className="social">
                <a href="#"><i className="bi bi-twitter"></i></a>
                <a href="#"><i className="bi bi-facebook"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
                <a href="#"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>

  </div>
    </div>
  );
};

export default Teams;
