import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div className="breadcrumbs">
    <div className="page-header d-flex align-items-center">
      <div className="container position-relative">
        <div className="row d-flex justify-content-start">
          <div className="col-lg-8 text-left">
            <h2>About Us</h2>
            <p>We're Passionate About Helping Businesses Thrive with Technology</p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div className="container">
        <ol>
          <li><a href="index.html">Home</a></li>
          <li>About</li>
        </ol>
      </div>
    </nav>
  </div>

  <div className="single-page">

    <section id="about">
      <div className="container">
        <div className="row">

            <div className="col-md-6 center">
            <p>At <span className="big-text">RedTheme</span> <span className="small-text">we are passionate about providing
                businesses with the IT solutions they need to succeed</span> in today's competitive marketplace. We
              offer a comprehensive suite of services, from IT infrastructure and security to cloud computing and
              business applications, all delivered with the expertise and dedication you deserve.</p>
            <div data-aos="fade-up">
              <ul id="skills">
                <li>
                  HTML
                  <div className="bar-container">
                    <span className="bar" data-bar='{ "color": "rgb(192 1 19)" }'>
                      <span className="pct">82%</span>
                    </span>
                  </div>
                </li>
                <li>
                  CSS
                  <div className="bar-container">
                    <span className="bar" data-bar='{ "color": "rgb(192 1 19)", "delay": 600 }'>
                      <span className="pct">100%</span>
                    </span>
                  </div>
                </li>
                <li>
                  PHP
                  <div className="bar-container">
                    <span className="bar" data-bar='{ "color": "rgb(192 1 19)", "delay": 1200 }'>
                      <span className="pct">60%</span>
                    </span>
                  </div>
                </li>
                <li>
                  IOS
                  <div className="bar-container">
                    <span className="bar" data-bar='{ "color": "rgb(192 1 19)", "delay": 2000 }'>
                      <span className="pct">80%</span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <a href="#contact" className="default-theme-btn-two">Contact Us <span></span></a>
          </div>

          <div className="col-md-6">
            <div className="list-center-wrap">
              <img src="assets/images/skills.jpg" alt="icon" />
            </div>
          </div>

        </div>

      </div>
    </section>

    <section id="featured" className="featured">
      <div className="container">
        <div className="section-header" data-aos="fade-up">
          <h2>Why Choose Us?</h2>
          <p>Your Business's IT Success is Our Driving Force</p>
        </div>
        <div className="row">
          <div className="col-md-5 center">
            <div className="list-center-wrap" data-aos="fade-up">
              <div className="img-fluid">
                <img src="assets/images/about.jpg" alt="icon" />
              </div>
            </div>
          </div>

          <div className="col-md-7 left">
            <div className="row">
              <div className="col-md-12">
                <p>In today's <span className="big-text">rapidly evolving technology</span> landscape, choosing the right IT
                  partner is crucial for your business success. At <span className="small-text">RedTheme</span>, we stand
                  out from the crowd with our unwavering commitment to quality, expertise, and customer satisfaction.
                  Here are just a few reasons why you should choose us:</p>
              </div>
              <div className="col-lg-6 left">
                <div className="list-wrap" data-aos="fade-up">
                  <div className="icon">
                    <img src="assets/images/icons/icon-1.svg" alt="icon" />
                  </div>
                  <div className="description">
                    <h4>Expertise,<span> Experience</span></h4>
                    <p>Deep knowledge and skill in a particular area. Practical knowledge gained from work or other
                      activities.</p>
                  </div>
                </div>

                <div className="list-wrap" data-aos="fade-up">
                  <div className="icon">
                    <img src="assets/images/icons/icon-2.svg" alt="icon" />
                  </div>
                  <div className="description">
                    <h4>Quality<span> Products</span></h4>
                    <p>A high standard of performance or excellence. Goods or services that are offered for sale</p>
                  </div>
                </div>

                <div className="list-wrap" data-aos="fade-up">
                  <div className="icon">
                    <img src="assets/images/icons/icon-3.svg" alt="icon" />
                  </div>
                  <div className="description">
                    <h4>Customer<span> Focused</span></h4>
                    <p>A person who buys or uses goods or services. Directed or concentrated towards a particular
                      object.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 right">
                <div className="list-wrap" data-aos="fade-up">
                  <div className="icon">
                    <img src="assets/images/icons/icon-4.svg" alt="icon" />
                  </div>
                  <div className="description">
                    <h4><span>Competitive </span>Pricing</h4>
                    <p>Able to match or surpass the offerings of competitors. The cost of a product or service</p>
                  </div>
                </div>

                <div className="list-wrap" data-aos="fade-up">
                  <div className="icon">
                    <img src="assets/images/icons/icon-5.svg" alt="icon" />
                  </div>
                  <div className="description">
                    <h4><span>Timely </span>Delivery</h4>
                    <p>Done or happening at the right time. The act of handing over something to someone</p>
                  </div>
                </div>

                <div className="list-wrap" data-aos="fade-up">
                  <div className="icon">
                    <img src="assets/images/icons/icon-6.svg" alt="icon" />
                  </div>
                  <div className="description">
                    <h4><span>24/7 </span>Support</h4>
                    <p>Available 24 hours a day, 7 days a week. Assistance or help</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </section>
  </div>


    </div>
  );
};

export default AboutUs;
