import React from "react";

const Blogs = () => {
  return (
    <div>
      <div className="breadcrumbs">
    <div className="page-header d-flex align-items-center">
      <div className="container position-relative">
        <div className="row d-flex justify-content-start">
          <div className="col-lg-8 text-left">
            <h2>Blogs</h2>
            <p>Our latest blog posts, featuring images and videos to enhance your reading experience.</p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div className="container">
        <ol>
          <li><a href="index.html">Home</a></li>
          <li>Blogs</li>
        </ol>
      </div>
    </nav>
  </div>
  <div className="single-page">

    <section id="recent-posts" className="recent-posts sections-bg">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4">
          <div className="col-lg-4">
            <article>
              <div className="post-img">
                <img src="assets/images/blog/blog-1.jpg" alt="" className="img-fluid" />
              </div>
              <p className="post-category">Domain & Hosting</p>
              <h2 className="title">
                <a href="single-blog.html">How to host website on any hosting provider?</a>
              </h2>
              <div className="d-flex align-items-center">
                <div className="post-meta">
                  <p className="post-author">William Smith</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Feb 1, 2022</time>
                  </p>
                </div>
              </div>
              <p className="text-4-line">To host a website on any hosting provider, you need to follow these steps: 1 Choose
                a hosting provider and sign up for a hosting plan. 2 Register a domain name </p>
            </article>
          </div>

          <div className="col-lg-4">
            <article>
              <div className="post-img">
                <img src="assets/images/blog/blog-2.jpg" alt="" className="img-fluid" />
              </div>
              <p className="post-category">Advertisement</p>
              <h2 className="title">
                <a href="single-blog.html">How to create add on google adwords?</a>
              </h2>
              <div className="d-flex align-items-center">
                <div className="post-meta">
                  <p className="post-author">Ana Will</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Oct 5, 2022</time>
                  </p>
                </div>
              </div>
              <p className="text-4-line">Google AdWords add-ons are third-party tools and services that can extend the
                functionality of the AdWords platform. They can help you with tasks such as managing your campaigns,
                tracking your performance,</p>
            </article>
          </div>

          <div className="col-lg-4">
            <article>
              <div className="post-img">
                <img src="assets/images/blog/blog-3.jpg" alt="" className="img-fluid" />
              </div>
              <p className="post-category">Marketing</p>
              <h2 className="title">
                <a href="single-blog.html">What is digital marketing and why is important?</a>
              </h2>
              <div className="d-flex align-items-center">
                <div className="post-meta">
                  <p className="post-author">Jhoni</p>
                  <p className="post-date">
                    <time datetime="2022-01-01">Dec 22, 2022</time>
                  </p>
                </div>
              </div>
              <p className="text-4-line">Digital marketing is the use of digital channels to promote or market products or
                services to consumers and businesses. It includes a wide range of activities, such as search engine
                optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, and
                email marketing.</p>
            </article>
          </div>

        </div>

      </div>
    </section>

  </div>
    </div>
  );
};

export default Blogs;
