import React from "react";

const Services = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row d-flex justify-content-start">
              <div className="col-lg-8 text-left">
                <h2>Services</h2>
                <p>
                  Harness the power of technology to boost productivity,
                  optimize operations, and achieve your business goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Services</li>
            </ol>
          </div>
        </nav>
      </div>

      <div className="single-page">
        <div id="services" className="section">
          <div className="top-icon-box position-relative">
            <div className="container position-relative">
              <div className="row gy-4">
                <div className="col-xl-4 col-md-6" data-aos="fade-right">
                  <a href="single-service.html">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="flaticon-001-edit-tools"></i>
                      </div>
                      <h4 className="title">Apps Design</h4>
                      <p>
                        Application design is the process of creating a digital
                        solution that meets the needs of users. It involves
                        understanding user needs, designing user interfaces, and
                        developing code.
                      </p>
                      <span></span>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 col-md-6" data-aos="fade-up">
                  <a href="single-service.html">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="flaticon-031-database"></i>
                      </div>
                      <h4 className="title">Web Hosting</h4>
                      <p>
                        Web hosting is the process of storing a website on a
                        server so that it can be accessed by visitors. It
                        involves renting space on a server, uploading files, and
                        maintaining the website.
                      </p>
                      <span></span>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-md-6" data-aos="fade-left">
                  <a href="single-service.html">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="flaticon-090-advertising"></i>
                      </div>
                      <h4 className="title">Social Media</h4>
                      <p>
                        Social media is a group of online applications that
                        enable users to create and share content. It is a
                        powerful tool for businesses to connect with customers,
                        promote products and services.
                      </p>
                      <span></span>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-md-6" data-aos="fade-right">
                  <a href="single-service.html">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="flaticon-075-earth-globe"></i>
                      </div>
                      <h4 className="title">SEO Optimization</h4>
                      <p>
                        SEO optimization is the process of improving a website's
                        ranking in search engine results pages. It involves
                        optimizing website content, building backlinks, and
                        using relevant keywords.
                      </p>
                      <span></span>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-md-6" data-aos="fade-up">
                  <a href="single-service.html">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="flaticon-132-edit-tools"></i>
                      </div>
                      <h4 className="title">IT Solutions</h4>
                      <p>
                        A cloud server is a virtual server that is hosted in the
                        cloud. It is a cost-effective and scalable solution for
                        businesses that need to store data and run applications.
                      </p>
                      <span></span>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-md-6" data-aos="fade-left">
                  <a href="single-service.html">
                    <div className="icon-box">
                      <div className="icon">
                        <i className="flaticon-023-code"></i>
                      </div>
                      <h4 className="title">Data Secuity</h4>
                      <p>
                        Protecting data from unauthorized access, disclosure It
                        is important for businesses to protect their data to
                        avoid financial losses, legal liability, and damage to
                        their reputation.
                      </p>
                      <span></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
