import React from "react";
import { Swiper, SwiperSlide,  } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import Card from "../components/Card";
const Home = () => {

  const cardData = [
    {
      id: 1,
      url: "/app-development/",
      icon: "flaticon-001-edit-tools",
      title: "Apps Development",
      description: "Application development is the art of transforming user needs into seamless digital solutions. Our process combines user research, intuitive interface design, and robust code development to create applications that deliver exceptional user experiences and drive results."
    },
    {
      id: 2,
      url: "/web-development/",
      icon: "flaticon-075-earth-globe",
      title: "Web Development",
      description: "Web development is the foundation of creating impactful online experiences. We specialize in building responsive, secure, and dynamic websites that align with your business goals, leveraging the latest technologies to ensure functionality, scalability, and an exceptional user experience."
    },
    {
      id: 3,
      url: "/social-media/",
      icon: "flaticon-090-advertising",
      title: "Social Media",
      description: "Social media is a group of online applications that enable users to create and share content. It is a powerful tool for businesses to connect with customers, promote products and services."
    },
    {
      id: 4,
      url: "/social-media/",
      icon: "flaticon-075-earth-globe",
      title: "SEO Optimization",
      description: "SEO optimization is the process of improving a website's ranking in search engine results pages. It involves optimizing website content, building backlinks, and using relevant keywords."
    },
    {
      id: 5,
      url: "/social-media/",
      icon: "flaticon-132-edit-tools",
      title: "IT Solutions",
      description: "A cloud server is a virtual server that is hosted in the cloud. It is a cost-effective and scalable solution for businesses that need to store data and run applications."
    },
    {
      id: 6,
      url: "/social-media/",
      icon: "flaticon-023-code",
      title: "Data Secuity",
      description: "Protecting data from unauthorized access, disclosure It is important for businesses to protect their data to avoid financial losses, legal liability, and damage to their reputation."
    },
  ]

  const cardOnClick =()=>{
    alert('in here')
  }
  return (
    <>
      <section id="hero" className="hero sticked-header-offset">

<div id="particles-js"> <canvas className="particles-js-canvas-el"></canvas></div>
<div id="repulse-circle-div"></div>
<div className="container position-relative">
  <div className="row gy-5 aos-init aos-animate">
    <div className="col-lg-6 d-flex flex-column justify-content-center text-left caption">
      <h1 data-aos="fade-up">IT Solutions and Business Services for Success</h1>
      <h2 data-aos="fade-up">Harness the power of technology to boost productivity, optimize operations, and achieve
        your business goals.</h2>
      <div className="social" data-aos="fade-up">
        <a href="#"><i className="bi bi-twitter"></i></a>
        <a href="#"><i className="bi bi-facebook"></i></a>
        <a href="#"><i className="bi bi-linkedin"></i></a>
        <a href="#"><i className="bi bi-instagram"></i></a>
      </div>
      <div className="d-flex justify-content-start">
        <a href="#contact" className="default-theme-btn-one mr-20" data-aos="fade-up">Contact Us<span></span></a>
        <a href="#services" className="default-theme-btn-two" data-aos="fade-up">Get Started<span></span></a>
      </div>
    </div>
    <div className="col-lg-6 d-flex flex-column align-items-right justify-content-center"><img
        src="assets/images/hero-img.png" className="img-fluid rounded-4 mb-4 mt-m70" alt="" /></div>
  </div>
</div>
</section>

<div id="services" className="section">
    <div className="top-icon-box position-relative">
      <div className="container position-relative">
        <div className="row gy-4">

          {/* <div className="col-xl-4 col-md-6" data-aos="fade-right">
            <a href="single-service.html">
              <div className="icon-box">
                <div className="icon"><i className="flaticon-001-edit-tools"></i></div>
                <h4 className="title">Apps Design</h4>
                <p>Application design is the process of creating a digital solution that meets the needs of users. It
                  involves understanding user needs, designing user interfaces, and developing code.</p>
                <span></span>
              </div>
            </a>
          </div> */}
        {/* <Card url="single-service.html" icon="flaticon-001-edit-tools" title="Apps Design" description="Application design is the process of creating a digital solution that meets the needs of users. It
                  involves understanding user needs, designing user interfaces, and developing code."/> */}
          <Card data={cardData} cardOnClick={cardOnClick}/>
        </div>
      </div>
    </div>
  </div>

  <section id="about" className="about">
    <div className="container">
      <div className="section-header" data-aos="fade-up">
        <h2>About Our Company</h2>
        <p>We're Passionate About Helping Businesses Thrive with Technology</p>
      </div>
      <div className="row">


        <div className="col-md-6 center">
          <p>At <span className="big-text">Light of Web</span> <span className="small-text">we are passionate about providing
              businesses with the IT solutions they need to succeed</span> in today's competitive marketplace. We
            offer a comprehensive suite of services, from IT infrastructure and security to cloud computing and
            business applications, all delivered with the expertise and dedication you deserve.</p>
          <div data-aos="fade-up">
            <ul id="skills">
              <li>
                HTML
                <div className="bar-container">
                  <span className="bar" data-bar='{ "color": "rgb(192 1 19)" }'>
                    <span className="pct">82%</span>
                  </span>
                </div>
              </li>
              <li>
                CSS
                <div className="bar-container">
                  <span className="bar" data-bar='{ "color": "rgb(192 1 19)", "delay": 600 }'>
                    <span className="pct">100%</span>
                  </span>
                </div>
              </li>
              <li>
                PHP
                <div className="bar-container">
                  <span className="bar" data-bar='{ "color": "rgb(192 1 19)", "delay": 1200 }'>
                    <span className="pct">60%</span>
                  </span>
                </div>
              </li>
              <li>
                IOS
                <div className="bar-container">
                  <span className="bar" data-bar='{ "color": "rgb(192 1 19)", "delay": 2000 }'>
                    <span className="pct">80%</span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <a href="#contact" className="default-theme-btn-two">Contact Us <span></span></a>
        </div>



        <div className="col-md-6">
          <div className="list-center-wrap">
            <img src="assets/images/skills.jpg" alt="icon" />
          </div>
        </div>

      </div>

    </div>
  </section>

  <section id="featured" className="featured">
    <div className="container">
      <div className="section-header" data-aos="fade-up">
        <h2>Why Choose Us?</h2>
        <p>Your Business's IT Success is Our Driving Force</p>
      </div>
      <div className="row">


        <div className="col-md-5 center">
          <div className="list-center-wrap" data-aos="fade-up">
            <div className="img-fluid">
              <img src="assets/images/about.jpg" alt="icon" />
            </div>
          </div>
        </div>



        <div className="col-md-7 left">
          <div className="row">
            <div className="col-md-12">
              <p>In today's <span className="big-text">rapidly evolving technology</span> landscape, choosing the right IT
                partner is crucial for your business success. At <span className="small-text">Light of Web</span>, we stand
                out from the crowd with our unwavering commitment to quality, expertise, and customer satisfaction.
                Here are just a few reasons why you should choose us:</p>
            </div>
            <div className="col-lg-6 left">
              <div className="list-wrap" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/images/icons/icon-1.svg" alt="icon" />
                </div>
                <div className="description">
                  <h4>Expertise,<span> Experience</span></h4>
                  <p>Deep knowledge and skill in a particular area. Practical knowledge gained from work or other
                    activities.</p>
                </div>
              </div>

              <div className="list-wrap" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/images/icons/icon-2.svg" alt="icon" />
                </div>
                <div className="description">
                  <h4>Quality<span> Products</span></h4>
                  <p>A high standard of performance or excellence. Goods or services that are offered for sale</p>
                </div>
              </div>

              <div className="list-wrap" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/images/icons/icon-3.svg" alt="icon" />
                </div>
                <div className="description">
                  <h4>Customer<span> Focused</span></h4>
                  <p>A person who buys or uses goods or services. Directed or concentrated towards a particular
                    object.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 right">
              <div className="list-wrap" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/images/icons/icon-4.svg" alt="icon" />
                </div>
                <div className="description">
                  <h4><span>Competitive </span>Pricing</h4>
                  <p>Able to match or surpass the offerings of competitors. The cost of a product or service</p>
                </div>
              </div>

              <div className="list-wrap" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/images/icons/icon-5.svg" alt="icon" />
                </div>
                <div className="description">
                  <h4><span>Timely </span>Delivery</h4>
                  <p>Done or happening at the right time. The act of handing over something to someone</p>
                </div>
              </div>

              <div className="list-wrap" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/images/icons/icon-6.svg" alt="icon" />
                </div>
                <div className="description">
                  <h4><span>24/7 </span>Support</h4>
                  <p>Available 24 hours a day, 7 days a week. Assistance or help</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </section>
  <div id="stats-counter" className="stats-counter section">
    <div className="container" data-aos="fade-up">
      <div className="row gy-4 align-items-center">
        <div className="col-lg-4">
          <div className="stats-item d-flex flex-column align-items-center">
            <div className="icon"><i className="flaticon-102-avatar"></i></div>
            <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1"
              className="purecounter"></span>
            <p>Happy Clients</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="stats-item d-flex flex-column align-items-center">
            <div className="icon"><i className="flaticon-060-award"></i></div>
            <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1"
              className="purecounter"></span>
            <p>Completed Projects</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="stats-item d-flex flex-column align-items-center">
            <div className="icon"><i className="flaticon-122-circular-clock"></i></div>
            <span data-purecounter-start="0" data-purecounter-end="453" data-purecounter-duration="1"
              className="purecounter"></span>
            <p>Hours Of Support</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section id="testimonials" className="testimonials">
    <div className="container" data-aos="fade-up">

      <div className="section-header">
        <h2>Testimonials</h2>
        <p>Our testimonials are a testament to the satisfaction of our clients</p>
      </div>

      <div className="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper-wrapper">

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <div className="d-flex align-items-center info-box">
                  <img src="assets/images/testimonials/testimonial-1.jpg" className="testimonial-img flex-shrink-0" alt="" />
                  <div>
                    <h3>Jhone Doe</h3>
                    <h4>CFO</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                        className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                    </div>
                  </div>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus.
                  Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <div className="d-flex align-items-center info-box">
                  <img src="assets/images/testimonials/testimonial-2.jpg" className="testimonial-img flex-shrink-0" alt="" />
                  <div>
                    <h3>Afa Rose</h3>
                    <h4>Web Designer</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                        className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                    </div>
                  </div>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus.
                  Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <div className="d-flex align-items-center info-box">
                  <img src="assets/images/testimonials/testimonial-3.jpg" className="testimonial-img flex-shrink-0" alt="" />
                  <div>
                    <h3>Keena Lara</h3>
                    <h4>Store Owner</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                        className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                    </div>
                  </div>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus.
                  Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <div className="d-flex align-items-center info-box">
                  <img src="assets/images/testimonials/testimonial-4.jpg" className="testimonial-img flex-shrink-0" alt="" />
                  <div>
                    <h3>Fizzi Brandon</h3>
                    <h4>Freelancer</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                        className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                    </div>
                  </div>
                </div>
                <p>
                  <i className="bi bi-quote quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus.
                  Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam.
                  <i className="bi bi-quote quote-icon-right"></i>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <section id="team" className="team sections-bg">
    <div className="container aos-init aos-animate" data-aos="fade-up">

      <div className="section-header">
        <h2>Expert Team</h2>
        <p>Our team of experienced professionals is committed to understanding your unique business needs</p>
      </div>

      <div className="row gy-4">

        <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <div className="member">
            <img src="assets/images/team/team-1.jpg" className="img-fluid" alt="" />
            <h4>Jhone Bi</h4>
            <span>Application Manager</span>
            <div className="social">
              <a href="#"><i className="bi bi-twitter"></i></a>
              <a href="#"><i className="bi bi-facebook"></i></a>
              <a href="#"><i className="bi bi-linkedin"></i></a>
              <a href="#"><i className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <div className="member">
            <img src="assets/images/team/team-2.jpg" className="img-fluid" alt="" />
            <h4>Sani Awesome</h4>
            <span>Social Media</span>
            <div className="social">
              <a href="#"><i className="bi bi-twitter"></i></a>
              <a href="#"><i className="bi bi-facebook"></i></a>
              <a href="#"><i className="bi bi-linkedin"></i></a>
              <a href="#"><i className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <div className="member">
            <img src="assets/images/team/team-3.jpg" className="img-fluid" alt="" />
            <h4>Andrio Willi</h4>
            <span>Content Writer</span>
            <div className="social">
              <a href="#"><i className="bi bi-twitter"></i></a>
              <a href="#"><i className="bi bi-facebook"></i></a>
              <a href="#"><i className="bi bi-linkedin"></i></a>
              <a href="#"><i className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
          <div className="member">
            <img src="assets/images/team/team-4.jpg" className="img-fluid" alt="" />
            <h4>Afa Jonson</h4>
            <span>Business Manager</span>
            <div className="social">
              <a href="#"><i className="bi bi-twitter"></i></a>
              <a href="#"><i className="bi bi-facebook"></i></a>
              <a href="#"><i className="bi bi-linkedin"></i></a>
              <a href="#"><i className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  <section id="call-to-action" className="call-to-action">
    <div className="container text-left aos-init aos-animate" data-aos="zoom-out">
      <div className="row align-items-center">
        <div className="col-lg-9">
          <h3>Let's Discuss your Projects</h3>
          <p>We pride ourselves with our ability to perform and deliver results. Use the form below to discuss your project needs with our team, we will get back asap</p>
        </div>
        <div className="col-lg-3 text-right">
          <a className="default-theme-btn-one" href="mailto:info@example.com">Conatct Us <span></span></a>
        </div>
      </div>
    </div>
  </section>
  <section id="recent-posts" className="recent-posts sections-bg">
    <div className="container" data-aos="fade-up">
      <div className="section-header">
        <h2>Blogs</h2>
        <p>Our latest blog posts, featuring images and videos to enhance your reading experience</p>
      </div>
      <div className="row gy-4">
        <div className="col-lg-4">
          <article>
            <div className="post-img">
              <img src="assets/images/blog/blog-1.jpg" alt="" className="img-fluid" />
            </div>
            <p className="post-category">Domain & Hosting</p>
            <h2 className="title">
              <a href="single-blog.html">How to host website on any hosting provider?</a>
            </h2>
            <div className="d-flex align-items-center">
              <div className="post-meta">
                <p className="post-author">William Smith</p>
                <p className="post-date">
                  <time dateTime="2022-01-01">Feb 1, 2022</time>
                </p>
              </div>
            </div>
            <p className="text-4-line">To host a website on any hosting provider, you need to follow these steps: 1 Choose a hosting provider and sign up for a hosting plan. 2 Register a domain name </p>
          </article>
        </div>

        <div className="col-lg-4">
          <article>
            <div className="post-img">
              <img src="assets/images/blog/blog-2.jpg" alt="" className="img-fluid" />
            </div>
            <p className="post-category">Advertisement</p>
            <h2 className="title">
              <a href="single-blog.html">How to create add on google adwords?</a>
            </h2>
            <div className="d-flex align-items-center">
              <div className="post-meta">
                <p className="post-author">Ana Will</p>
                <p className="post-date">
                  <time dateTime="2022-01-01">Oct 5, 2022</time>
                </p>
              </div>
            </div>
            <p className="text-4-line">Google AdWords add-ons are third-party tools and services that can extend the functionality of the AdWords platform. They can help you with tasks such as managing your campaigns, tracking your performance,</p>
          </article>
        </div>

        <div className="col-lg-4">
          <article>
            <div className="post-img">
              <img src="assets/images/blog/blog-3.jpg" alt="" className="img-fluid" />
            </div>
            <p className="post-category">Marketing</p>
            <h2 className="title">
              <a href="single-blog.html">What is digital marketing and why is important?</a>
            </h2>
            <div className="d-flex align-items-center">
              <div className="post-meta">
                <p className="post-author">Jhoni</p>
                <p className="post-date">
                  <time dateTime="2022-01-01">Dec 22, 2022</time>
                </p>
              </div>
            </div>
            <p className="text-4-line">Digital marketing is the use of digital channels to promote or market products or services to consumers and businesses. It includes a wide range of activities, such as search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, and email marketing.</p>
          </article>
        </div>

      </div>

    </div>
  </section>

  <div id="download-app" className="download-app-promo">
    <div className="download-app-promo-text">
      <div className="download-app-promo-text__tagline">The best way to get news on the go</div>
      <div className="download-app-promo-text__download">Download Our App Free.</div>
    </div>
    <div className="download-app-promo__section">
      <div className="download-app-promo-subsection">
        <a className="download-app-promo-subsection--link download-app-promo-subsection--playstore" href="#"
          target="_parent">
          <img className="download-app-promo__play-store" src="assets/images/google_play_store.svg" alt="google play" />
        </a>
        <a className="download-app-promo-subsection--link download-app-promo-subsection--appstore" href="#"
          target="_parent">
          <img className="download-app-promo__app-store" src="assets/images/ios_app_store.svg" alt="ios app store" />
        </a>
      </div>
    </div>
  </div>

  <div id="clients" className="clients section">
    <div className="container" data-aos="zoom-out">

    <Swiper
    modules={[ Autoplay]}
      spaceBetween={50}
      slidesPerView={5}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      loop={true}
    >
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-1.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-2.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-3.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-4.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-5.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-6.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-7.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="swiper-slide"><img src="assets/images/clients/c-8.jpg" className="img-fluid" alt=""/></div></SwiperSlide>
    </Swiper>



    </div>
  </div>

    </>
  );
};

export default Home;
